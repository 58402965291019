<template>
  <v-container
    class="pa-0"
    fluid
  >
    <FilterHeader
      @filter="filterAction"
      @clear="clearAction"
    />

    <v-row dense>
      <v-col
        class="pl-4"
        cols="12"
        md="6"
      >
        <v-checkbox
          v-model="filter.is_temporary"
          label="Temporários"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-checkbox
          v-model="filter.is_composition"
          label="Compostos"
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-text-field
          v-model="filter.name"
          outlined
          label="Nome / Descrição"
          rounded
          dense
          hide-details
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        cols="12"
        md="12"
      >
        <v-autocomplete
          v-model="filter.ncm"
          :items="ncm.items"
          item-text="title"
          item-value="id"
          label="NCM"
          outlined
          rounded
          dense
          hide-details
          @focus.once="getNcms"
        >
          <template v-slot:no-data>
            <div
              v-if="ncm.isLoading"
              class="pa-4"
            >
              <v-progress-linear
                indeterminate
                color="primary"
              />
            </div>
            <h6
              v-else
              class="text-subtitle-2 text-center"
            >
              Nenhum NCM encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="filter.reference"
          outlined
          label="Referência"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="filter.status"
          outlined
          :items="status"
          item-text="title"
          item-value="id"
          label="Status"
          rounded
          dense
          hide-details
          clearable
          clear-icon="fas fa-times"
        />
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row dense>
      <v-col cols="12">
        <v-autocomplete
          v-model="filter.category"
          :items="category.items"
          item-text="name"
          item-value="id"
          label="Categoria"
          placeholder="Categoria, Subcategoria, Segmento ou Datas especiais"
          outlined
          rounded
          dense
          hide-details
          clearable
          clear-icon="fas fa-times"
          @focus.once="getCategory"
        >
          <template v-slot:no-data>
            <div
              v-if="category.isLoading"
              class="pa-4"
            >
              <v-progress-linear
                indeterminate
                color="primary"
              />
            </div>
            <h6
              v-else
              class="text-subtitle-2 text-center pa-4"
            >
              Nenhuma categoria encontrada
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-autocomplete
          v-model="filter.color"
          :items="color.items"
          item-text="name"
          item-value="id"
          label="Cor"
          outlined
          rounded
          dense
          hide-details
          clearable
          clear-icon="fas fa-times"
          @focus.once="getColors"
        >
          <template v-slot:no-data>
            <div
              v-if="color.isLoading"
              class="pa-4"
            >
              <v-progress-linear
                indeterminate
                color="primary"
              />
            </div>
            <h6
              v-else
              class="text-subtitle-2 text-center pa-4"
            >
              Nenhuma cor encontrada
            </h6>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-avatar
              size="30"
              color="primary"
            >
              <v-img
                v-if="item.image_color"
                :src="$store.state.imagePath + item.image_color"
              />
              <v-icon
                v-else
                small
                color="white"
              >
                fas fa-palette
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-autocomplete
          v-model="filter.factor"
          :items="factor.items"
          item-text="cost"
          item-value="id"
          label="Fator"
          outlined
          rounded
          dense
          hide-details
          @focus.once="getFactors"
        >
          <template v-slot:no-data>
            <div
              v-if="factor.isLoading"
              class="pa-1"
            >
              <v-progress-linear
                indeterminate
                color="primary"
              />
            </div>
            <h6
              v-else
              class="text-subtitle-2 text-center pa-4"
            >
              Nenhum fator encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="filter.print"
          :items="print.items"
          item-text="name"
          item-value="id"
          label="Gravação"
          clear-icon="fas fa-times"
          outlined
          rounded
          dense
          clearable
          hide-details
          @focus.once="getPrints"
        >
          <template v-slot:no-data>
            <div
              v-if="print.isLoading"
              class="pa-1"
            >
              <v-progress-linear
                indeterminate
                color="primary"
              />
            </div>
            <h6
              v-else
              class="text-subtitle-2 text-center pa-4"
            >
              Nenhuma gravação encontrada
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row dense>
      <v-col cols="12">
        <v-autocomplete
          v-model="filter.vendors"
          :items="vendor.items"
          item-text="trading_name"
          item-value="id"
          label="Fornecedores"
          outlined
          rounded
          dense
          hide-details
          @update:search-input="onSearchInput"
          @focus.once="getVendors"
        >
          <template v-slot:append-item>
            <div
              v-intersect="onIntersect"
              class="pa-2"
            >
              Carregando...
            </div>
          </template>
          <template v-slot:no-data>
            <div
              v-if="vendor.isLoading"
              class="pa-1"
            >
              <v-progress-linear
                indeterminate
                color="primary"
              />
            </div>
            <h6
              v-else
              class="text-subtitle-2 text-center pa-4"
            >
              Nenhum fornecedor encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="6">
        <v-text-field
          v-model="filter.select_vendors_reference"
          outlined
          label="Cód. do Forn."
          rounded
          dense
          type="number"
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="filter.vendor_status"
          :items="vendor_status"
          item-text="title"
          item-value="id"
          label="Status do Forn."
          outlined
          rounded
          dense
          hide-details
        >
          <template v-slot:item="{ item }">
            <v-list-item-avatar size="20">
              <v-icon
                small
                :color="item.color"
              >
                {{ item.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-icon
          color="primary"
          small
        >
          fas fa-calendar-alt
        </v-icon>
        <span class="text-body-2"> Data de atualização do Fornecedor</span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.modalTo"
          v-mask="'##/##/####'"
          outlined
          label="De:"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.modalFrom"
          v-mask="'##/##/####'"
          outlined
          label="Até:"
          rounded
          dense
          hide-details
        />
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row dense>
      <v-col cols="12">
        <v-icon
          color="primary"
          small
        >
          fas fa-hand-holding-usd
        </v-icon>
        <span class="text-body-2"> Custo Fixo</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="filter.cost_from"
          v-mask="['#,##', '##,##', '###,##']"
          outlined
          label="De:"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="filter.cost_to"
          v-mask="['#,##', '##,##', '###,##']"
          outlined
          label="Até:"
          rounded
          dense
          hide-details
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterHeader from '@/components/FilterHeader'
import debounce from 'lodash/debounce'

export default {
  components: {
    FilterHeader,
  },
  data: () => ({
    filter: {},
    searchText: '',
    isLastPage: false,
    ncm: {
      items: [],
      isLoading: true,
    },
    category: {
      items: [],
      isLoading: true,
    },
    color: {
      items: [],
      isLoading: true,
    },
    print: {
      items: [],
      isLoading: true,
    },
    vendor: {
      items: [],
      isLoading: true,
      page: 1, // Página atual
    },
    factor: {
      items: [],
      isLoading: true,
    },
    cost: 1,
    status: [
      { id: 1, title: 'Ativo' },
      { id: 0, title: 'Inativo' },
    ],
    composition: [
      { id: 1, title: 'Sim' },
      { id: 0, title: 'Não' },
    ],
    vendor_status: [
      { id: 1, title: 'Ok', icon: 'fas fa-check', color: 'success' },
      { id: 2, title: 'Consultar', icon: 'fas fa-search', color: 'blue' },
      { id: 3, title: 'Sem estoque', icon: 'fas fa-box-open', color: 'error' },
      {
        id: 4,
        title: 'Fora de linha',
        icon: 'fas fa-times-circle',
        color: 'grey',
      },
    ],
  }),
  created() {
    this.debouncedGetVendors = debounce(this.getVendors, 300)
  },
  methods: {
    onSearchInput(value) {
      this.searchText = value
      this.vendor.page = 1
      this.isLastPage = false // Resetamos o indicador de última página
      this.debouncedGetVendors()
    },
    getColors() {
      this.$api
        .get('product_colors')
        .then((res) => {
          this.color.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.color.isLoading = false
        })
    },
    getCategory() {
      this.$api
        .get('get_product_categories')
        .then((res) => {
          this.category.items = [
            { name: 'Sem Categoria', id: 'no_category' },
            ...res.data.data,
          ]
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.category.isLoading = false
        })
    },
    getNcms() {
      this.$api
        .get('/ncms')
        .then((res) => {
          this.ncm.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.ncm.isLoading = false
        })
    },
    onIntersect() {
      this.vendor.page += 1
      this.getVendors()
    },
    getPrints() {
      this.$api
        .get('product_prints')
        .then((res) => {
          this.print.items = [
            { id: 'no_print', name: 'Sem Gravação' },
            ...res.data.data,
          ]
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.print.isLoading = false
        })
    },
    getVendors() {
      this.vendor.isLoading = true
      this.$api
        .get(
          `vendors?page=${this.vendor.page}&search=${
            this.searchText ? this.searchText : ''
          }`
        )
        .then((res) => {
          const data = res.data.data

          if (data.length === 0) {
            this.isLastPage = true // Não há mais dados para carregar
          } else {
            if (this.vendor.page === 1) {
              this.vendor.items = data
            } else {
              this.vendor.items = [...this.vendor.items, ...data]
            }
          }
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.vendor.isLoading = false
        })
    },
    getFactors() {
      this.$api
        .get('product_factors')
        .then((res) => {
          this.factor.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.factor.isLoading = false
        })
    },
    filterAction() {
      this.$emit('filter', this.filter)
    },
    clearAction() {
      this.$emit('clear')
      this.filter = {}
    },
  },
}
</script>
